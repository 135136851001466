import React, { useState, useEffect } from 'react'
import axios from './axios'
import { v4 as uuidv4 } from 'uuid';
import pauseMe from 'pause-me';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import Result from './components/result';

const App = () => {

  useEffect(() => {
    setUid(uuidv4())
  }, [])

  const [uid, setUid] = useState('')
  const [respond, setRespond] = useState('');
  const [carBatteryCapacity, setCarBatteryCapacity] = useState(null)
  const [minChargeLevel, setMinChargeLevel] = useState(null)
  const [durationStay, setDurationStay] = useState(null)
  const [currentChargeLevel, setCurrentChargeLevel] = useState(null)
  const [btnActive, setBtnActive] = useState(false)
  const [timeout, setTimeout] = useState(false)
  const [result, setResult] = useState([])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const { res } = axios.post('ev/readings', {
      uid: uid,
      carBatteryCapacity: parseInt(carBatteryCapacity),
      minChargeLevel: parseInt(minChargeLevel),
      durationStay: parseInt(durationStay),
      currentChargeLevel: parseInt(currentChargeLevel)
    })
      .then(function (response) {
        setRespond(response.data.result.message)
        setBtnActive(true)
        timeOut()
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleResult = async (e) => {
    e.preventDefault()
    const { res } = axios.post('ev/result', {
      uid: uid
    })
      .then(function (response) {
        setResult(response.data.result.message)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const timeOut = () => {
    const myTimeout = pauseMe(() => {
      setTimeout(true)
    }, 60000);
  }

  console.log(result)

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col bg-white rounded shadow-lg  absolute left-0 right-0 mx-auto mt-5  lg:max-w-[450px] p-5 md:w-100 md:m-w-[450px]">

      <label
        className="font-semibold text-sm">
        Car Battery Capacity [kWh]
      </label>
      <div className='text-xs text-gray-500'>{`What is your Car's Battery Capacity?`}</div>
      <input
        className="flex items-center h-12 px-4 bg-gray-200 mt-2 rounded focus:outline-none focus:ring-2 placeholder:text-[13px]"
        type="number"
        value={carBatteryCapacity || ''}
        max={100}
        min={0}
        onChange={(e) => setCarBatteryCapacity(e.target.value)} />

      <label
        className="font-semibold text-sm mt-5">
        Minimum Charge level  [%]
      </label>
      <div className='text-xs text-gray-500'>{`What is the allowed Minimum Charge level?`}</div>
      <input
        className="flex items-center h-12 px-4 bg-gray-200 mt-2 rounded focus:outline-none focus:ring-2 placeholder:text-[13px]"
        type="number"
        value={minChargeLevel || ''}
        max={100}
        min={0}
        onChange={(e) => setMinChargeLevel(e.target.value)} />

      <label
        className="font-semibold text-sm mt-5">
        Duration of stay  [Hours]
      </label>
      <div className='text-xs text-gray-500'>{`How long will you stay at this charging point?`}</div>
      <input
        className="flex items-center h-12 px-4 bg-gray-200 mt-2 rounded focus:outline-none focus:ring-2 placeholder:text-[13px]"
        type="number"
        value={durationStay || ''}
        max={24}
        min={1}
        onChange={(e) => setDurationStay(e.target.value)} />

      <label
        className="font-semibold text-sm mt-5">
        Current Charge level  [%]
      </label>
      <div className='text-xs text-gray-500'>{`What is the Current Charge level of your car battery?`}</div>
      <input
        className="flex items-center h-12 px-4 bg-gray-200 mt-2 rounded focus:outline-none focus:ring-2 placeholder:text-[13px]"
        type="number"
        value={currentChargeLevel || ''}
        max={100}
        min={0}
        onChange={(e) => setCurrentChargeLevel(e.target.value)} />

      <div className='flex flex-row justify-between w-100 gap-x-6'>
        <button
          style={{ width: '20%' }}
          className="flex items-center justify-center h-12 px-6 w-100 bg-blue-600 mt-8 font-semibold text-md text-blue-100 hover:bg-blue-700" type="submit">
          Send
        </button>
        {btnActive ? (
          <button
            disabled={!timeout}
            style={{ width: '100%' }}
            onClick={handleResult}
            type='button'
            className="flex items-center justify-center h-12 px-6 w-100 bg-green-600 mt-8 font-semibold text-md text-blue-100 disabled:bg-gray-500"
          >
            {timeout ? 'Optimize my charging' : (
              <div className='circle'>
                <CountdownCircleTimer
                  isPlaying
                  duration={60}
                  colors={[]}
                  colorsTime={[]}
                >
                  {({ remainingTime }) => remainingTime}
                </CountdownCircleTimer>
              </div>
            )}
          </button>
        ) : (
          <button
            disabled={true}
            style={{ width: '100%' }}
            type='button'
            className="flex items-center justify-center h-12 px-6 w-100 bg-green-600 mt-8 font-semibold text-md text-blue-100 disabled:bg-gray-500"
          >Optimize my charging</button>
        )}
      </div>
      {respond !== '' ? (
        <div className="bg-green-500 text-xl font-semibold text-white p-4 mt-5 text-center">
          {respond}
        </div>)
        : ''
      }
      {result !== '' ? (
        <div className="w-100 mt-8  text-xl font-semibold text-white text-center">
          <div class="flex flex-col w-100">
            <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-2 inline-block min-w-full">
                <div class="overflow-hidden" style={{ maxHeight: '260px', overflow: 'auto'}}>
                  <table class="min-w-full text-center" style={{ width: '100%' }}>

                    {result.map((obj, idx) => (
                      <tbody key={idx}>
                        <tr class="border-b" style={{ display: 'inline-flex', gap: '15px'}}>
                          <td class="text-sm text-gray-900 font-light py-4 whitespace-nowrap">
                            {obj.timestamp}
                          </td>
                          <td class="text-sm text-gray-900 font-light py-4 whitespace-nowrap">
                            {obj.action}
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : ''}

    </form>
  )
}

export default App